import request from '@/plugins/axios'

// ================推特帐号接口==============
export function getAccountList(params) {
  // 获取 脸书 帐号列表
  return request({
    url: '/facebook/accounts',
    method: 'get',
    params
  })
}
export function getAccountAll(params) {
  // 获取 脸书 帐号列表
  return request({
    url: '/facebook/accounts:get_all',
    method: 'get',
    params
  })
}

export function updateAccount(accountId, data) {
  // 更新脸书twitter帐号备注
  return request({
    url: `/facebook/accounts/${accountId}`,
    method: 'patch',
    data
  })
}

// ==============帐号控制权限接口===================
export function getAssignByUser(userId, params) {
  // 获取某用户控制的 脸书 帐号
  return request({
    url: `/facebook/accountAssigns/user/${userId}`,
    method: 'get',
    params
  })
}

export function addAssignAccount(userId, accountId) {
  // 给某用户增加某 脸书 账号的控制权限
  return request({
    url: 'facebook/accountAssigns',
    method: 'post',
    data: {
      userId,
      accountId
    }
  })
}

export function deleteAssignAccount(userId, accountId) {
  // 删除某用户的某 脸书 账号的控制权限
  return request({
    url: 'facebook/accountAssigns',
    method: 'delete',
    data: {
      userId,
      accountId
    }
  })
}

export function getAssignByAccount(accountId, params) {
  // 获取某脸书帐号 对应的使用者
  return request({
    url: `/facebook/accountAssigns/account/${accountId}`,
    method: 'get',
    params
  })
}

// ================发帖任务接口==============
export function getPostTaskList(params) {
  // 获取 脸书 发帖任务列表
  return request({
    url: '/facebook/post_tasks',
    method: 'get',
    params
  })
}

export function getPostTaskScreenshot(id) {
  // 获取youTube发帖任务的截图
  return request({
    url: `/facebook/postTasks/${id}/screenshot`,
    method: 'get'
  })
}

export function addPostTask(params) {
  const data = {}
  Object.keys(params).forEach(key => {
    if (
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ''
    ) {
      data[key] = params[key]
    }
  })
  // 新增 脸书 发帖任务
  return request({
    url: '/facebook/post_tasks',
    method: 'post',
    data
  })
}

export function delPostTaskByIds(ids) {
  // 删除发帖任务
  return request({
    url: 'facebook/post_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}

// ==============演示帐号接口===================
export function getFacebookDemoAccount() {
  // 获取推特的演示帐号
  return request({
    url: 'facebook/demoAccounts',
    method: 'get'
  })
}

export function addFacebookDemoAccount(accountId) {
  // 获取演示帐号
  return request({
    url: 'facebook/demoAccounts',
    method: 'post',
    data: {
      accountId: accountId
    }
  })
}

export function deleteFacebookDemoAccount(accountId) {
  // 删除演示帐号
  return request({
    url: `facebook/demoAccounts/${accountId}`,
    method: 'delete'
  })
}
