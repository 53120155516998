<template>
  <section class="group-detail">
    <div class="top">
      <el-form>
        <el-form-item>
          <el-radio-group v-model="queryForm.account_type" @change="handleType">
            <el-radio value="twitter" label="twitter"></el-radio>
            <el-radio value="facebook" label="facebook"></el-radio>
            <el-radio value="youtube" label="youtube"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-back"
          @click="backFun"
        >
          返回
        </el-button>
      </div>
    </div>
    <div v-show="queryForm.account_type === 'twitter'">
      <el-table
        :height="windowsHeight - 200"
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
      >
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column
          prop="screen_name"
          label="用户名"
          width="150"
        ></el-table-column>
        <el-table-column prop="name" label="昵称" width="150"></el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
          <template slot-scope="scope" class="btnEdit">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="login_account"
          label="登录帐号"
          width="150"
        ></el-table-column>
        <el-table-column label="主页链接" width="200">
          <template slot-scope="scope">
            <div class="tag-read" style="cursor: pointer">
              https://twitter.com/{{ scope.row.screen_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="getStatusTip(scope.row.status, statusMap)"
              placement="top"
            >
              <el-tag
                :type="getStatusType(scope.row.status, statusMap)"
                disable-transitions
              >
                {{ getStatusLabel(scope.row.status, statusMap) }}
              </el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="is_risk" label="风险">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_risk ? 'danger' : 'success'"
              disable-transitions
            >
              {{ scope.row.is_risk ? '有风险' : '无风险' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_test"
          label="演示帐号"
          v-if="userInfo.is_staff"
        >
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="分组" width="150">
          <template slot-scope="scope">
            {{ scope.row.group_entity ? scope.row.group_entity.name : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_entity"
          label="使用者"
          v-if="userInfo.is_staff"
        >
          <template slot-scope="scope">
            {{
              scope.row.user_entity
                ? scope.row.user_entity.oauth_account
                  ? scope.row.user_entity.oauth_account
                  : scope.row.user_entity.username
                : null
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="使用者备注"
          v-if="userInfo.is_staff"
          width="300"
        >
          <template slot-scope="scope">
            {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_login_time"
          label="最后登录时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div v-show="queryForm.account_type === 'facebook'">
      <el-table
        :height="windowsHeight - 200"
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
      >
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="name" label="昵称" width="180"></el-table-column>
        <el-table-column prop="index_url" label="主页" width="220">
          <template slot-scope="scope">
            <div class="tag-read" style="cursor: pointer">
              {{ scope.row.index_url }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
          <template slot-scope="scope" class="btnEdit">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="login_account"
          label="登录帐号"
          width="180"
        ></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="getStatusTip(scope.row.status, statusMap)"
              placement="top"
            >
              <el-tag
                :type="getStatusType(scope.row.status, statusMap)"
                disable-transitions
              >
                {{ getStatusLabel(scope.row.status, statusMap) }}
              </el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="is_risk" label="风险">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_risk ? 'danger' : 'success'"
              disable-transitions
            >
              {{ scope.row.is_risk ? '有风险' : '无风险' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_test"
          label="演示帐号"
          v-if="userInfo.is_staff"
        >
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="分组" width="150">
          <template slot-scope="scope">
            {{ scope.row.group_entity ? scope.row.group_entity.name : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_entity"
          label="使用者"
          v-if="userInfo.is_staff"
        >
          <template slot-scope="scope">
            {{
              scope.row.user_entity
                ? scope.row.user_entity.oauth_account
                  ? scope.row.user_entity.oauth_account
                  : scope.row.user_entity.username
                : null
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="使用者备注"
          v-if="userInfo.is_staff"
          width="350"
        >
          <template slot-scope="scope">
            {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_login_time"
          label="最后登录时间"
          width="170"
        ></el-table-column>
        <el-table-column
          prop="regist_time"
          label="注册时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div v-show="queryForm.account_type === 'youtube'">
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
        :height="windowsHeight - 200"
      >
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column
          prop="user_name"
          label="用户名"
          width="180"
        ></el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
          <template slot-scope="scope" class="btnEdit">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="login_account"
          label="登录帐号"
          width="180"
        ></el-table-column>
        <el-table-column label="主页链接" prop="index_url" width="250">
          <template slot-scope="scope">
            <div class="tag-read" style="cursor: pointer">
              {{ scope.row.index_url }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="getStatusTip(scope.row.status, statusMap)"
              placement="top"
            >
              <el-tag
                :type="getStatusType(scope.row.status, statusMap)"
                disable-transitions
              >
                {{ getStatusLabel(scope.row.status, statusMap) }}
              </el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="is_risk" label="风险">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.is_risk ? 'danger' : 'success'"
              disable-transitions
            >
              {{ scope.row.is_risk ? '有风险' : '无风险' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_test"
          label="演示帐号"
          v-if="userInfo.is_staff"
        >
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="分组" width="150">
          <template slot-scope="scope">
            {{ scope.row.group_entity ? scope.row.group_entity.name : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_entity"
          label="使用者"
          v-if="userInfo.is_staff"
          width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.user_entity
                ? scope.row.user_entity.oauth_account
                  ? scope.row.user_entity.oauth_account
                  : scope.row.user_entity.username
                : null
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="使用者备注"
          v-if="userInfo.is_staff"
          width="350"
        >
          <template slot-scope="scope">
            {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
          </template>
        </el-table-column>
        <el-table-column
          prop="last_login_time"
          label="最后登录时间"
          width="170"
        ></el-table-column>
        <el-table-column
          prop="regist_time"
          width="170"
          label="注册时间"
        ></el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :currentPage="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </section>
</template>
<script>
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getAccountList as twitterApi } from '@/api/twitter'
import { getAccountList as facebookApi } from '@/api/facebook'
import { getAccountList as youtubeApi } from '@/api/youTube'
export default {
  components: {},
  data() {
    return {
      group: null,
      queryForm: {
        account_type: 'twitter'
      },
      userInfo: this.$store.state.user.userInfo,
      listLoading: false,
      list: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      statusMap: {
        1: {
          type: 'success',
          label: '正常'
        },
        0: {
          type: 'warning',
          label: '异常'
        },
        '-1': {
          type: 'danger',
          label: '失效',
          tip: '该账号因为异常行为已被暂停使用'
        }
      }
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.group = this.$route.params.group
    this.getData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    handleType(data) {
      this.pageIndex = 1
      this.total = 0
      this.list = []
      this.getData()
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize
      this.getData()
    },
    currentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getData()
    },
    getData() {
      this.listLoading = true
      if (this.queryForm.account_type === 'twitter') {
        twitterApi({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          group: this.group
        }).then(res => {
          this.total = res.all_count
          this.list = res.items
          this.listLoading = false
        })
      } else if (this.queryForm.account_type === 'facebook') {
        facebookApi({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          group: this.group
        }).then(res => {
          this.total = res.all_count
          this.list = res.items
          this.listLoading = false
        })
      } else if (this.queryForm.account_type === 'youtube') {
        youtubeApi({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          group: this.group
        }).then(res => {
          this.total = res.all_count
          this.list = res.items
          this.listLoading = false
        })
      }
    },
    backFun() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.group-detail {
  width: 100%;
  height: calc(100vh - 85px);
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  ::v-deep .el-form-item {
    margin-bottom: 5px;
  }
  .top {
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
}
</style>
