import request from '@/plugins/axios'

// ================youTube帐号接口==============
export function getAccountList(params) {
  // 获取youTube帐号列表
  return request({
    url: '/youtube/accounts',
    method: 'get',
    params
  })
}
export function getAccountAll(params) {
  // 获取youTube帐号列表
  return request({
    url: '/youtube/accounts:get_all',
    method: 'get',
    params
  })
}

export function updateAccount(accountId, data) {
  // 更新youTube帐号备注
  return request({
    url: `/youtube/accounts/${accountId}`,
    method: 'patch',
    data
  })
}

// ================youTube发帖接口==============
export function addPostTask(params) {
  const data = {}
  Object.keys(params).forEach(key => {
    if (
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ''
    ) {
      data[key] = params[key]
    }
  })
  // 新增youTube发帖任务
  return request({
    url: '/youtube/post_tasks',
    method: 'post',
    data
  })
}

export function getPostTaskList(params) {
  // 获取youTube发帖任务列表
  return request({
    url: '/youtube/post_tasks',
    method: 'get',
    params
  })
}

export function getPostTaskScreenshot(id) {
  // 获取youTube发帖任务的截图
  return request({
    url: `/youtube/postTasks/${id}/screenshot`,
    method: 'get'
  })
}

export function delPostTaskByIds(ids) {
  // 批量逻辑删除youTube发帖任务
  return request({
    url: 'youtube/post_tasks:batch_delete',
    method: 'post',
    data: { ids }
  })
}

// ==============帐号控制权限接口===================
export function getAssignByUser(userId, params) {
  // 获取某用户控制的推特帐号
  return request({
    url: `/youtube/accountAssigns/user/${userId}`,
    method: 'get',
    params
  })
}

export function addAssignAccount(userId, accountId) {
  // 给某用户增加某账号的控制权限
  return request({
    url: 'youtube/accountAssigns',
    method: 'post',
    data: {
      userId,
      accountId
    }
  })
}

export function deleteAssignAccount(userId, accountId) {
  // 删除某用户的某账号的控制权限
  return request({
    url: 'youtube/accountAssigns',
    method: 'delete',
    data: {
      userId,
      accountId
    }
  })
}

export function getAssignByAccount(accountId, params) {
  // 获取某帐号 对应的使用者
  return request({
    url: `/youtube/accountAssigns/account/${accountId}`,
    method: 'get',
    params
  })
}

// ==============演示帐号接口===================
export function getYouTubeDemoAccount() {
  // 获取推特的演示帐号
  return request({
    url: 'youtube/demoAccounts',
    method: 'get'
  })
}

export function addYouTubeDemoAccount(accountId) {
  // 获取演示帐号
  return request({
    url: 'youtube/demoAccounts',
    method: 'post',
    data: {
      accountId: accountId
    }
  })
}

export function deleteYouTubeDemoAccount(accountId) {
  // 删除演示帐号
  return request({
    url: `youtube/demoAccounts/${accountId}`,
    method: 'delete'
  })
}
