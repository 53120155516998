export function getStatusType(status, statusMap) {
  if (statusMap[status] === undefined) {
    return 'info'
  } else {
    return statusMap[status].type
  }
}

export function getStatusLabel(status, statusMap) {
  if (statusMap[status] === undefined) {
    return '未知状态'
  } else {
    return statusMap[status].label
  }
}

export function getStatusTip(status, statusMap) {
  if (statusMap[status] === undefined) {
    return '未知状态'
  } else {
    if (statusMap[status].tip === undefined) {
      return statusMap[status].label
    } else {
      return statusMap[status].tip
    }
  }
}
